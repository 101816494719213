<div class="text-left">

  <div class="btn-group">
          <button type="button" class="btn-shadow btn-hover-shine d-inline-flex align-items-center btn btn-danger btn-lg" (click)="volver()"
                        style="border-radius: 3rem; width: 115px !important" *ngIf="editable && !isModal">
                  <span class="material-icons mr-1">
                      arrow_back
                  </span> <span class="justify-content-center ml-2">Atrás</span>
        </button>
        <button type="button" class="btn-shadow btn-hover-shine d-inline-flex align-items-center btn btn-dark ml-3 btn-lg" (click)="editar()"
                        style="border-radius: 3rem; width: 115px !important" *ngIf="editable" [disabled]="!showEditarButton"> 
            <span class="material-icons mr-1">
                edit
            </span> <span class="justify-content-center ml-2">Editar</span>
        </button>
        <button type="button" 
                style="border-radius: 3rem; width: 115px !important"
                class="btn-shadow btn-hover-shine d-inline-flex align-items-center btn btn-danger ml-3 btn-lg" 
                (click)="cerrar()"
            *ngIf="editable && isModal"> 
            <span class="material-icons mr-1">
                close
            </span> <span class="justify-content-center ml-2">Cerrar</span>
        </button>
        <button type="button" class="btn-shadow btn-hover-shine d-inline-flex align-items-center ml-3 btn btn-danger btn-lg" (click)="cancelar()"
                        style="border-radius: 3rem; width: 115px !important" *ngIf="!editable" [disabled]="process"> 
            <span class="material-icons mr-1">
                clear
            </span> Cancelar
        </button>
        <button type="button" class="btn-shadow btn-hover-shine d-inline-flex align-items-center btn btn-success ml-3 btn-lg" 
                (click)="actualizar()" 
                [ngClass]="{'not-allowed' : !valid}"
                [disabled]="process || !valid" *ngIf="!editable" style="border-radius: 3rem; width: 115px !important">
            <span class="material-icons mr-1">
                loop
             </span> Actualizar
                <span class="spinner-border spinner-grow spinner-grow-sm text-primary d-inline-flex" role="status" aria-hidden="true"
                *ngIf="process">
                </span>
        </button>
  </div>

</div>
